<template>
  <div class="faq">
    <div class="header">
      <div class="logo">
        <div class="badge">
          <img alt="Dice" src="@/assets/icons/question-circle-1.svg" />
        </div>
        FAQ
      </div>
    </div>
    <div class="body">
      <div class="containers">
        <div class="group">
          <Question name="Через сколько приходит выплата?">
            Вывод средств осуществляется от 5 минут до 24 часов с момента
            создания заявки.
          </Question>

          <Question name="Что делать если введены неверные реквизиты?">
            Если такие реквизиты не существуют в выбранной платежной системе, то
            средства вернуться на Ваш баланс автоматически.
          </Question>

          <Question name="Мне не пришел депозит">
            В случае, если вам не пришел депозит после 10 минут ожидания, вы
            можете обратиться в службу поддержки, заполнить анкету на проверку
            вашего депозита
          </Question>

          <Question name="Я получил блокировку, что делать? ">
            В случае блокировки аккаунта, вы можете один раз обратиться в
            поддержку для перепроверки, если в ходе перепроверки данного решения
            выяснится, что блокировка выдана верно, то вам запрещается
            пользоваться услугами сайта
          </Question>

          <Question name='Что такое "Ранги"?'>
            Ранги - особая система поощрения игроков, за игровую активность на
            сайте. Чем выше уровень, тем выше привелегий он вам дает.
          </Question>
        </div>
        <div class="group">
          <Question name="Как отменить выплату?">
            Для отмены выплаты нажмите "Отмена" в разделе "Вывод". Баланс
            аккаунта мгновенно пополнится на сумму отмененной заявки.
          </Question>

          <Question name="Как пополнить баланс?">
            Для пополнения баланса откройте раздел “Кошелек” и выберите пункт
            “Пополнение”. Дальше вам нужно выбрать способ оплаты и сумму
            пополнения.
          </Question>

          <Question name="За что можно получить блокировку?">
            Блокировку аккаунта можно получить по следующим причинам: - Абуз
            сайта (Пункт 23.7 пользовательского соглашения) - Мультиаккаунт
            (Регистрация нескольких аккаунтов)
          </Question>

          <Question name='Что такое "Партнерская программа"?'>
            Партнерская программа - это система вознаграждения участников за
            привлечение новых клиентов. Наличие этой системы позволяет Вам
            приводить людей по своей уникальной ссылке и получать от 5% ДО 50%
            от прибыли сайта раз в неделю на свой счет. Создание второго
            аккаунта по своей реферальной ссылке, наказуемо блокировкой и не
            выплатой!
          </Question>

          <Question name="Что такое Wager?">
            сумма, которую игрок должен отыграть, т.е. создать игр на указанную
            сумму
          </Question>
        </div>
      </div>
      <div v-if="user && user.youtuber">
        <button class="balance" @click="getBalance">Получить баланс</button>
      </div>
    </div>
  </div>
</template>

<script>
import Question from "@/components/Faq/Question";
import { mapState } from "vuex";
export default {
  components: {
    Question,
  },
  computed: mapState("user", ["user"]),
  methods: {
    async getBalance() {
      try {
        const { data } = await this.$http.get("user/demo");
        if (data.error) return this.$toast.error(data.error);
        let { message, balances } = data.response;
        if (balances) this.$store.commit("user/balance", balances);
        this.$toast.info(message);
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.faq {
  border-radius: 6px;
  padding: 25px;
  margin-bottom: 25px;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f5fd;
    padding-bottom: 20px;
    margin-bottom: 15px;
    .logo {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      .badge {
        img {
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
        background-color: #0085ff;
        // background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
        border-radius: 8px;
        display: flex;
        width: 45px;
        height: 45px;
        margin-right: 15px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .group {
      width: calc(50% - 10px);
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .balance {
    padding: 15px 25px;
    border-radius: 6px;
    background-color: #0085ff;
    box-shadow: 0px 15px 15px rgb(0 133 255 / 25%);
    color: white;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
