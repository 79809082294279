<template>
  <div class="question">
    <div class="header" @click="active = !active">
      <div class="badge">?</div>
      {{ name }}
    </div>
    <div v-if="active" class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.question {
  border: 1px solid #f3f5fd;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 30px;
  .header {
    display: flex;
    padding: 15px;
    align-items: center;
    font-weight: 500;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
    .badge {
      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
      background-color: #0085ff;
      box-shadow: 0px 15px 15px rgba(0, 133, 255, 0.25);
      background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
      border-radius: 8px;
      display: flex;
      width: 45px;
      height: 45px;
      font-size: 20px;
      color: white;
      font-weight: 500;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    padding: 25px;
    font-size: 14px;
    color: #a1a6bb;
    border-top: 1px solid #f3f5fd;
  }
}
</style>
